<template>
  <section>
    <b-alert
      v-if="result === 'success'"
      :show="dismissCountDown"
      dismissible
      variant="success"
      @dismissed="
        result = '';
        formIsVisible = false;
      "
    >
      <p>Changes had been saved successfully</p>
    </b-alert>
    <b-alert
      v-else-if="result === 'failed'"
      :show="dismissCountDown"
      dismissible
      variant="danger"
      @dismissed="
        result = '';
        formIsVisible = false;
      "
    >
      <p>Please refresh and try again</p>
    </b-alert>
    <div style="min-width: 120px">
      <b-button
        v-if="!formIsVisible && !result"
        @click="editMode"
        size="sm"
        variant="primary"
        class="mr-2"
        >Edit
      </b-button>
      <b-button
        v-if="!formIsVisible && !result"
        @click="showLog"
        size="sm"
        variant="secondary"
        >Log
      </b-button>
    </div>
    <b-collapse v-model="formIsVisible">
      <div style="min-width: 300px">
        <b-overlay :show="isLoading" rounded="sm">
          <template #overlay>
            <div class="text-center">
              <b-spinner></b-spinner>
              <p>Loading ...</p>
            </div>
          </template>
          <div class="p-2">
            <b-form>
              <b-row class="mb-1">
                <b-col cols="4">
                  <small>Category</small>
                </b-col>
                <b-col v-if="formIsVisible">
                  <b-input v-model="value.category" v-focus size="sm" />
                </b-col>
              </b-row>
              <b-row class="mb-1">
                <b-col cols="4">
                  <small>SubCategory</small>
                </b-col>
                <b-col>
                  <b-input v-model="value.subCategory" size="sm" />
                </b-col>
              </b-row>
              <b-row class="mb-1">
                <b-col cols="4">
                  <small>Vendor</small>
                </b-col>
                <b-col>
                  <b-input v-model="value.vendor" size="sm" />
                </b-col>
              </b-row>
              <b-row class="mb-1">
                <b-col cols="4">
                  <small>Chat Status</small>
                </b-col>
                <b-col>
                  <b-select
                    v-model="value.chatStatus"
                    size="sm"
                    :options="status"
                  />
                </b-col>
              </b-row>
              <b-row class="mt-3">
                <b-col>
                  <b-button
                    class="float-right"
                    size="sm"
                    variant="primary"
                    @click="submitChanges"
                    >Submit
                  </b-button>
                  <b-button
                    @click="formIsVisible = false"
                    class="float-right mr-2"
                    size="sm"
                    >Cancel
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </div>
        </b-overlay>
      </div>
    </b-collapse>

    <b-modal centered hide-footer v-model="logModal" size="lg">
      <b-table :items="items" :fields="fields" :responsive="true">
        <template v-slot:cell(updatedAt)="data">
          <span>
            {{ data.value | datetimelong }}
          </span>
        </template>
        <template v-slot:cell(updatedBy)="data">
          <span>
            {{ data.value | username }}
          </span>
        </template>
      </b-table>
    </b-modal>
  </section>
</template>

<script>
/*global _*/

import httpclient from '@/mixins/HttpHandler';

export default {
  props: {
    value: {
      type: Object,
      default: function () {
        return {
          botId: 0,
          chatId: '',
          category: '',
          subCategory: '',
          vendor: '',
          chatStatus: '',
        };
      },
    },
    chatId: {
      type: String,
    },
  },
  mixins: [httpclient],
  data() {
    return {
      formIsVisible: false,
      isLoading: false,
      dismissCountDown: 5,
      result: '',
      status: ['Follow up', 'Solved', 'No response'],
      logModal: false,
      log: [],
      fields: [
        { key: 'updatedAt', label: 'Updated At (UTC)' },
        {
          key: 'updatedBy',
          label: 'Updated By',
        },
        { key: 'before', label: 'Data Before Update' },
        { key: 'after', label: 'Data After Update' },
      ],
    };
  },
  watch: {
    'value.chatId': function (chatId) {
      if (chatId !== this.chatId) {
        this.formIsVisible = false;
        this.result = '';
      }
    },
  },
  computed: {
    items() {
      return _.orderBy(this.log, ['updatedAt'], ['desc']);
    },
  },
  methods: {
    editMode() {
      this.formIsVisible = true;
      this.$emit('edit-mode');
    },
    submitChanges() {
      const vm = this;
      let formItem = _.cloneDeep(vm.value);
      vm.isLoading = true;
      vm.httpclient
        .post('/api/chatcategories', formItem)
        .then(() => {
          vm.result = 'success';
          vm.$emit('data-updated', formItem);
        })
        .catch((err) => {
          vm.result = 'failed';
          // eslint-disable-next-line no-console
          console.log(err);
        })
        .then(() => {
          vm.isLoading = false;
          vm.formIsVisible = false;
        });
      this.$emit('input', this.value);
    },
    showLog() {
      const vm = this;
      vm.logModal = true;
      vm.httpclient
        .get('/api/chatcategories/' + vm.chatId + '/logs')
        .then((resp) => {
          if (resp.data) {
            vm.log = resp.data;
          } else {
            vm.log = [];
          }
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err);
        });
    },
  },
  filters: {
    username(name) {
      return name.split('____')[0];
    },
    datetimeToLocale(date) {
      return new Date(date + 'Z').toLocaleString();
    },
  },
};
</script>
