<template>
  <div>
    <b-row>
      <b-col class="mb-3">
        <h1>Chat History Summary</h1>
      </b-col>
    </b-row>
    <b-form ref="field-form">
      <div class="form-group row">
        <div class="col-sm-2">
          <label>Bot</label>
        </div>
        <div class="col-sm-2">
          <bot-selector
            @botChanged="
              (botId, botName) => {
                queryParams.botId = botId;
                queryParams.botName = botName;
              }
            "
          />
        </div>
        <div class="col-sm-2">
          <label>Chat ID</label>
        </div>
        <div class="col-sm-2">
          <b-input
            class="form-control-sm"
            v-model.trim="queryParams.chatId"
          ></b-input>
        </div>
      </div>
      <div class="form-group row">
        <div class="col-sm-2">
          <label>Intent Top 1</label>
        </div>
        <div class="col-sm-2">
          <b-input
            class="form-control-sm"
            v-model.trim="queryParams.categoryTop1"
          ></b-input>
        </div>
        <div class="col-sm-2">
          <label>Intent Top 2</label>
        </div>
        <div class="col-sm-2">
          <b-input
            class="form-control-sm"
            v-model.trim="queryParams.categoryTop2"
          ></b-input>
        </div>
        <div class="col-sm-2">
          <label>Intent Top 3</label>
        </div>
        <div class="col-sm-2">
          <b-input
            class="form-control-sm"
            v-model.trim="queryParams.categoryTop3"
          ></b-input>
        </div>
      </div>
      <div class="form-group row">
        <div class="col-sm-2">
          <label>Category</label>
        </div>
        <div class="col-sm-2">
          <input-with-blank-option
            @change="
              (v) => {
                this.queryParams.category = v.value;
                this.queryParams.emptyCategoryOnly = v.showBlankOnly;
              }
            "
          />
        </div>
        <div class="col-sm-2">
          <label>SubCategory</label>
        </div>
        <div class="col-sm-2">
          <input-with-blank-option
            @change="
              (v) => {
                this.queryParams.subCategory = v.value;
                this.queryParams.emptySubCategoryOnly = v.showBlankOnly;
              }
            "
          />
        </div>
        <div class="col-sm-2">
          <label>Vendor</label>
        </div>
        <div class="col-sm-2">
          <input-with-blank-option
            @change="
              (v) => {
                this.queryParams.vendor = v.value;
                this.queryParams.emptyVendorOnly = v.showBlankOnly;
              }
            "
          />
        </div>
      </div>
      <div class="form-group row">
        <div class="col-sm-2">
          <label>Date Range</label>
        </div>
        <div class="col-sm-4">
          <date-range-picker
            ref="picker"
            :locale-data="{ firstDay: 1, format: 'DD-MM-YYYY HH:mm:ss' }"
            :minDate="minDate"
            :maxDate="maxDate"
            :timePicker="true"
            :ranges="defaultRanges()"
            v-model="dateRange"
          >
            <div slot="input" slot-scope="picker" class="form-inline">
              <small class="text-muted mr-1">from</small>
              <span class="form-control form-control-sm">{{
                picker.startDate | datetime
              }}</span>
              <small class="text-muted mx-1">to</small>
              <span class="form-control form-control-sm">{{
                picker.endDate | datetime
              }}</span>
            </div>
          </date-range-picker>
        </div>
        <div class="col-sm-2">
          <label>Chat Duration (secs)</label>
        </div>
        <div class="col-sm-4 form-inline">
          <small class="text-muted mr-2">more than</small>
          <b-input
            class="form-control-sm input-range"
            v-model.trim="queryParams.chatDurationSecsGreaterThan"
          ></b-input>
          <small class="text-muted mx-2">less than</small>
          <b-input
            class="form-control-sm input-range"
            v-model.trim="queryParams.chatDurationSecsLessThan"
          ></b-input>
        </div>
      </div>
      <div class="form-group row">
        <div class="col-sm-2">
          <label>Chat Line Count</label>
        </div>
        <div class="col-sm-4 form-inline">
          <small class="text-muted mr-2">more than</small>
          <b-input
            class="form-control-sm input-range"
            v-model.trim="queryParams.chatLineCountGreaterThan"
          ></b-input>
          <small class="text-muted mx-2">less than</small>
          <b-input
            class="form-control-sm input-range"
            v-model.trim="queryParams.chatLineCountLessThan"
          ></b-input>
        </div>
        <div class="col-sm-2">
          <label>Chat Line Count (client)</label>
        </div>
        <div class="col-sm-4 form-inline">
          <small class="text-muted mr-2">more than</small>
          <b-input
            class="form-control-sm input-range"
            v-model.trim="queryParams.chatLineCountClientGreaterThan"
          ></b-input>
          <small class="text-muted mx-2">less than</small>
          <b-input
            class="form-control-sm input-range"
            v-model.trim="queryParams.chatLineCountClientLessThan"
          ></b-input>
        </div>
      </div>
      <div class="form-group row">
        <div class="col-sm-2">
          <label>Score Top 1</label>
        </div>
        <div class="col-sm-4 form-inline">
          <small class="text-muted mr-2">more than</small>
          <b-input
            class="form-control-sm input-range"
            v-model.trim="queryParams.scoreTop1GreaterThan"
          ></b-input>
          <small class="text-muted mx-2">less than</small>
          <b-input
            class="form-control-sm input-range"
            v-model.trim="queryParams.scoreTop1LessThan"
          ></b-input>
        </div>
        <div class="col-sm-2">
          <label>Score Top 2</label>
        </div>
        <div class="col-sm-4 form-inline">
          <small class="text-muted mr-2">more than</small>
          <b-input
            class="form-control-sm input-range"
            v-model.trim="queryParams.scoreTop2GreaterThan"
          ></b-input>
          <small class="text-muted mx-2">less than</small>
          <b-input
            class="form-control-sm input-range"
            v-model.trim="queryParams.scoreTop2LessThan"
          ></b-input>
        </div>
      </div>
      <div class="form-group row">
        <div class="col-sm-2">
          <label>Score Top 3</label>
        </div>
        <div class="col-sm-4 form-inline">
          <small class="text-muted mr-2">more than</small>
          <b-input
            class="form-control-sm input-range"
            v-model.trim="queryParams.scoreTop3GreaterThan"
          ></b-input>
          <small class="text-muted mx-2">less than</small>
          <b-input
            class="form-control-sm input-range"
            v-model.trim="queryParams.scoreTop3LessThan"
          ></b-input>
        </div>
        <div class="col-sm-2">
          <label>Chat Status</label>
        </div>
        <div class="col-sm-4 form-inline">
          <b-form-select
            size="sm"
            v-model="queryParams.chatStatus"
            :options="chatStatus"
          ></b-form-select>
        </div>
      </div>
      <div class="form-group row">
        <div class="col-sm-2">
          <label>Channel</label>
        </div>
        <div class="col-sm-4 form-inline">
          <treeselect
            multiple
            required
            v-model="queryParams.channel"
            :options="channels"
          />
        </div>
        <div class="col-sm-2">
          <label>Question</label>
        </div>
        <div class="col-sm-4 form-inline">
          <treeselect
            multiple
            required
            v-model="queryParams.question"
            :options="questions"
          />
        </div>
      </div>
      <div class="form-group row">
        <div class="col-12">
          <div v-if="isBusy" class="text-center my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
          <b-button
            variant="primary"
            size="sm"
            class="float-right mx-2"
            @click="getData"
          >
            <b-icon-search />
            Search
          </b-button>
          <b-button
            variant="primary"
            size="sm"
            class="float-right mx-2"
            @click="exportData"
          >
            <b-icon-file-earmark-excel />
            Export
          </b-button>
          <b-button
            variant="primary"
            size="sm"
            class="float-right mx-2"
            @click="exportDataForCs"
          >
            <b-icon-file-earmark-excel />
            Export For CS
          </b-button>
        </div>
      </div>
    </b-form>
    <div ref="table-container">
      <small class="text-muted"
        >Current Page: {{ currentPage }}. Total rows: {{ rows }}</small
      >
      <b-pagination
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        size="sm"
        align="right"
        limit="10"
        v-if="rows > 0"
      ></b-pagination>
      <section class="my-3 d-inline-block">
        <b-row>
          <b-col>
            <small class="text-muted ml-1">Show/Hide Columns </small>
            <treeselect
              multiple
              value-consists-of="LEAF_PRIORITY"
              v-model="selectedColumns"
              :options="availableColumns"
            />
          </b-col>
        </b-row>
      </section>
      <b-table
        bordered
        small
        :responsive="true"
        striped
        :items="items"
        :fields="visibleColumns"
        :per-page="perPage"
        :current-page="currentPage"
        :busy="isBusy"
        class="chat-histories-table"
      >
        <template v-slot:table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>
        <template v-slot:cell(2)="data">
          <span
            class="clickable text-primary"
            @click="searchChatId(data.value)"
          >
            {{ data.value }}
          </span>
        </template>
        <template v-slot:cell(3)="data"
          >{{ data.item[3] | datetimelong }}
        </template>
        <template v-slot:cell(4)="data"
          >{{ data.item[4] | datetimelong }}
        </template>
        <template v-slot:cell(25)="data"
          >{{ data.item[25] | datetimelong }}
        </template>
        <template v-slot:cell(action)="data">
          <category-form
            v-model="chatCategoryForm"
            :chat-id="data.item[2]"
            @edit-mode="loadCategoryForm(data)"
            @data-updated="updatedCategory(data, $event)"
          ></category-form>
        </template>
      </b-table>
      <b-pagination
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        size="sm"
        align="right"
        limit="10"
        v-if="rows > 0"
      ></b-pagination>
    </div>
    <div>
      <b-modal
        size="lg"
        scrollable
        hide-footer
        ref="chat-modal"
        header-class="chat-id-header"
      >
        <template v-slot:modal-title>
          <span>Chat ID:</span>
          <input
            ref="chat-id"
            class="chat-id"
            style="cursor: pointer"
            readonly
            @click="selectChatId"
            :value="chatId"
          />
        </template>
        <chat-text :chats="chats" />
      </b-modal>
    </div>
  </div>
</template>

<script>
import DateRangePicker from 'vue2-daterange-picker';
import dayjs from 'dayjs';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import ChatText from '@/components/ChatHistories/ChatText';
import httpclient from '@/mixins/HttpHandler';
import CategoryForm from '@/components/ChatHistories/CategoryForm';
import BotSelector from '@/components/UI/BotSelector';
import InputWithBlankOption from '@/components/UI/InputWithBlankOption.vue';

const defaultChatCategoryForm = {
  botId: 0,
  chatId: '',
  category: '',
  subCategory: '',
  vendor: '',
  chatStatus: 'Follow up',
};

export default {
  mixins: [httpclient],
  components: {
    InputWithBlankOption,
    DateRangePicker,
    ChatText,
    CategoryForm,
    BotSelector,
  },
  mounted() {
    this.queryParams.botName = this.$store.state.selectedBotName;
    this.queryParams.botId = this.$store.state.selectedBotId;

    let datepicker = document.getElementsByClassName('reportrange-text');
    datepicker[0].classList.add('form-control-sm');

    this.dateRange = {
      startDate: dayjs().add(-1, 'days').startOf('day').toDate(),
      endDate: dayjs().startOf('day').toDate(),
    };
  },
  data() {
    const fields = [
      {
        key: '1',
        label: 'Bot Name',
        sortable: false,
      },
      {
        key: '2',
        label: 'Chat ID',
        sortable: false,
        tdClass: 'overflow-truncate-100',
      },
      {
        key: '3',
        label: 'Chat Start Time',
        sortable: true,
      },
      {
        key: '4',
        label: 'Chat End Time',
        sortable: true,
      },
      {
        key: '5',
        label: 'Chat Duration (secs)',
        sortable: true,
      },
      {
        key: '6',
        label: 'Chat Line Count',
        sortable: true,
      },
      {
        key: '7',
        label: 'Chat Line Count (client)',
        sortable: true,
      },
      {
        key: '8',
        label: 'Intent Top 1',
        sortable: true,
      },
      {
        key: '9',
        label: 'Intent Top 2',
        sortable: true,
      },
      {
        key: '10',
        label: 'Intent Top 3',
        sortable: true,
      },
      {
        key: '11',
        label: 'Score Top 1',
        sortable: true,
      },
      {
        key: '12',
        label: 'Score Top 2',
        sortable: true,
      },
      {
        key: '13',
        label: 'Score Top 3',
        sortable: true,
      },
      {
        key: '14',
        label: 'Rating',
        sortable: true,
      },
      {
        key: '15',
        label: 'Channel',
        sortable: true,
      },
      {
        key: '16',
        label: 'Question',
        sortable: true,
      },
      {
        key: '17',
        label: 'Member Code',
        sortable: true,
      },
      {
        key: '18',
        label: 'Category',
        sortable: true,
      },
      {
        key: '19',
        label: 'SubCategory',
        sortable: true,
      },
      {
        key: '20',
        label: 'Vendor',
        sortable: true,
      },
      {
        key: '21',
        label: 'Custom Values',
        sortable: true,
      },
      {
        key: '22',
        label: 'Manual Category',
        sortable: true,
      },
      {
        key: '23',
        label: 'Manual SubCategory',
        sortable: true,
      },
      {
        key: '24',
        label: 'Manual Vendor',
        sortable: true,
      },
      {
        key: '25',
        label: 'Chat Status',
        sortable: true,
      },
      {
        key: '26',
        label: 'Last Updated At (UTC)',
        sortable: true,
      },
      {
        key: '27',
        label: 'Last Updated By',
        sortable: true,
      },
    ];
    return {
      path: 'chathistorysummaries',
      bots: [],
      queryParams: {
        botId: 0,
        chatStartTimeFrom: null,
        chatStartTimeTo: null,
        botName: null,
        chatId: null,
        categoryTop1: null,
        categoryTop2: null,
        categoryTop3: null,
        chatDurationSecsLessThan: null,
        chatDurationSecsGreaterThan: null,
        chatLineCountLessThan: null,
        chatLineCountGreaterThan: null,
        chatLineCountClientLessThan: null,
        chatLineCountClientGreaterThan: null,
        scoreTop1LessThan: null,
        scoreTop1GreaterThan: null,
        scoreTop2LessThan: null,
        scoreTop2GreaterThan: null,
        scoreTop3LessThan: null,
        scoreTop3GreaterThan: null,
        channel: ['Agent', 'Bot', 'Bot To Agent', 'Unknown'],
        question: ['Yes', 'No', 'Unknown'],
        category: null,
        subCategory: null,
        vendor: null,
        chatStatus: 'All',
        limit: 1000,
        emptyCategoryOnly: false,
        emptySubCategoryOnly: false,
        emptyVendorOnly: false,
      },
      dateRange: {},
      fields: fields,
      items: [],
      perPage: 10,
      currentPage: 1,
      chats: [],
      chatId: null,
      channels: [
        { id: 'Agent', label: 'Agent' },
        { id: 'Bot', label: 'Bot' },
        {
          id: 'Bot To Agent',
          label: 'Bot To Agent',
        },
        { id: 'Unknown', label: 'Unknown' },
      ],
      questions: [
        { id: 'Yes', label: 'Yes' },
        { id: 'No', label: 'No' },
        { id: 'Unknown', label: 'Unknown' },
      ],
      chatStatus: ['All', 'blank', 'Follow up', 'Solved', 'No response'],
      selectedColumns: fields.map((a) => a.key),
      chatCategoryForm: {
        chatId: '',
        category: '',
        subCategory: '',
        vendor: '',
        chatStatus: '',
      },
    };
  },
  computed: {
    minDate() {
      return dayjs().add(-2, 'months').set('date', 1).startOf('day').toDate();
    },
    maxDate() {
      return dayjs().add(1, 'days').toDate();
    },
    rows() {
      return this.items.length;
    },
    availableColumns() {
      return [
        {
          id: 'a',
          label: 'All',
          children: this.fields.map((a) => {
            return { id: a.key, label: a.label };
          }),
        },
      ];
    },
    visibleColumns() {
      const selectedColumns = this.selectedColumns;
      let fields = this.fields.filter((a) => {
        return selectedColumns.includes(a.key);
      });
      if (this.items.length > 0) {
        fields.push({
          key: 'action',
          label: '',
          sortable: false,
        });
      }
      return fields;
    },
    username() {
      return this.$store.state.userInfo.name;
    },
  },
  methods: {
    getData() {
      const vm = this;

      this.queryParams.chatStartTimeFrom = dayjs(
        this.dateRange.startDate
      ).format('YYYY-MM-DD HH:mm:ss');
      this.queryParams.chatStartTimeTo = dayjs(this.dateRange.endDate).format(
        'YYYY-MM-DD HH:mm:ss'
      );

      vm.httpclient
        .post('api/chathistorysummaries/getlist', this.queryParams)
        .then(function (resp) {
          if (resp.data) {
            vm.items = resp.data;
            vm.currentPage = 1;

            const div = vm.$refs['table-container'];
            if (div) {
              div.scrollTop = 0;
            }
          } else {
            vm.items = [];
          }
        })
        .catch(function (e) {
          vm.items = [];
          // eslint-disable-next-line no-console
          console.log(e);
        });
    },
    exportData() {
      this.queryParams.chatStartTimeFrom = dayjs(
        this.dateRange.startDate
      ).format('YYYY-MM-DD HH:mm:ss');
      this.queryParams.chatStartTimeTo = dayjs(this.dateRange.endDate).format(
        'YYYY-MM-DD HH:mm:ss'
      );

      this.isBusy = true;
      const vm = this;
      vm._downloadFile(
        'api/chathistorysummaries/export',
        vm.queryParams,
        function () {
          vm.isBusy = false;
        }
      );
    },
    exportDataForCs() {
      this.queryParams.chatStartTimeFrom = dayjs(
        this.dateRange.startDate
      ).format('YYYY-MM-DD HH:mm:ss');
      this.queryParams.chatStartTimeTo = dayjs(this.dateRange.endDate).format(
        'YYYY-MM-DD HH:mm:ss'
      );

      this.isBusy = true;
      const vm = this;
      vm._downloadFile(
        'api/chathistorysummaries/exportforcs',
        this.queryParams,
        function () {
          vm.isBusy = false;
        }
      );
    },
    searchChatId(chatId) {
      this.chatId = chatId;
      const vm = this;

      vm.httpclient
        .post('api/chathistories/getlist', { chatId: chatId, limit: 1000 })
        .then(function (resp) {
          vm.chats = resp.data;
          vm.$refs['chat-modal'].show();
        })
        .catch(function () {
          vm.items = [];
        });
    },
    selectChatId() {
      this.$refs['chat-id'].focus();
      this.$refs['chat-id'].select();
    },
    loadCategoryForm(data) {
      let row = data.item;
      let chatCategoryForm = { ...defaultChatCategoryForm };
      chatCategoryForm.botId = row[0];
      chatCategoryForm.chatId = row[2];
      if (row[22]) chatCategoryForm.category = row[22];
      if (row[23]) chatCategoryForm.subCategory = row[23];
      if (row[24]) chatCategoryForm.vendor = row[24];
      if (row[25]) chatCategoryForm.chatStatus = row[25];

      this.chatCategoryForm = chatCategoryForm;
    },
    updatedCategory(data, updatedData) {
      let list = [...this.items];
      const updatedIndex = list.findIndex((a) => a[2] === updatedData.chatId);
      list[updatedIndex][22] = updatedData.category;
      list[updatedIndex][23] = updatedData.subCategory;
      list[updatedIndex][24] = updatedData.vendor;
      list[updatedIndex][25] = updatedData.chatStatus;
      list[updatedIndex][26] = 'Just now';
      list[updatedIndex][27] = this.username;
      this.items = list;
    },

    defaultRanges() {
      const today = dayjs().startOf('day').toDate();
      const tomorrow = dayjs().add(1, 'days').startOf('day').toDate();
      const yesterday = dayjs().add(-1, 'days').startOf('day').toDate();
      const thisMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);
      const thisMonday = dayjs().startOf('isoWeek');
      const prevMonday = dayjs().add(-7, 'days').startOf('isoWeek');

      const previousHour = dayjs().add(-1, 'hours').startOf('hour').toDate();
      const thisHour = dayjs().startOf('hour').toDate();
      const nextHour = dayjs().add(1, 'hours').startOf('hour').toDate();

      return {
        'Previous Hour': [previousHour, thisHour],
        'This Hour': [thisHour, nextHour],
        Today: [today, tomorrow],
        Yesterday: [yesterday, today],
        'Previous Week': [prevMonday, thisMonday],
        'This Week': [thisMonday, tomorrow],
        'This Month': [thisMonthStart, tomorrow],
      };
    },
  },
};
</script>

<style lang="scss">
.chat-id {
  width: calc(100% - 80px);
  font-size: 0.85rem;
  display: inline-block;
  background: none;
  border: none;
}

.chat-id-header > .modal-title {
  width: 100%;
}
</style>
