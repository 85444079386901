<template>
  <div>
    <b-form-checkbox
      v-model="showBlankOnly"
      size="sm"
      title="show empty only"
      @change="checkboxChecked"
    >
      <b-form-input
        v-model="input"
        size="sm"
        debounce="500"
        :disabled="showBlankOnly"
        :placeholder="placeholder"
        @change="emitChanges"
      />
    </b-form-checkbox>
  </div>
</template>

<script>
export default {
  name: 'InputWithBlankOption',
  props: {
    initialValue: {
      type: String,
      default: '',
    },
    initialBlankOnly: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.input = this.initialValue;
    this.showBlankOnly = this.initialBlankOnly;
  },
  watch: {
    initialValue(v) {
      this.input = v;
    },
    initialBlankOnly(v) {
      this.showBlankOnly = v;
    },
  },
  data() {
    return {
      input: '',
      showBlankOnly: false,
      placeholder: '',
    };
  },
  methods: {
    emitChanges() {
      this.$emit('input', {
        value: this.input,
        showBlankOnly: this.showBlankOnly,
      });
      this.$emit('change', {
        value: this.input,
        showBlankOnly: this.showBlankOnly,
      });
    },
    checkboxChecked(checked) {
      if (checked) {
        this.placeholder = 'show empty only';
        this.input = '';
        this.showBlankOnly = true;
      } else {
        this.placeholder = '';
        this.showBlankOnly = false;
      }
      this.emitChanges();
    },
  },
};
</script>

<style scoped></style>
